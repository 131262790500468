import enterpriseApi from '@/api/enterprise.api'
import thingApi from '@/api/thing.api'
import { configurationService } from '@/business/configurationService'
import filterService from '@/business/filter.service'
import { smartTruckDispatcherService } from '@/business/smartTruckDispatcherService'
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue'
import FrontSelectorDialogComponent from '@/components/smart-truck-dispatcher/front-selector-dialog/FrontSelectorDialogComponent.vue'
import HarvesterDialogComponent from '@/components/smart-truck-dispatcher/harvester-dialog/HarvesterDialogComponent.vue'
import TruckDialogComponent from '@/components/smart-truck-dispatcher/truck-dialog/TruckDialogComponent.vue'
import FrontDialogComponent from '@/components/smart-truck-dispatcher/front-dialog/FrontDialogComponent.vue'
import WaitEmptyTrucksComponent from '@/components/smart-truck-dispatcher/wait-emtpy-trucks/WaitEmptyTrucksComponent.vue'
import { HOUR_SELECTOR } from './constants';
import ConfigurationsConstants from '@/constants/smartTruckDispatcher.constants'
import i18n from '@/i18n'
import { TYPE_KEY } from '@colven/common-domain-lib/lib'
import { mapActions, mapMutations } from 'vuex'
import TruckDispatchComponent from '@/components/smart-truck-dispatcher/TruckDispatch/TruckDispatchComponent.vue'
import moment from 'moment';
import selectorService from '@/business/selectorService';
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue';
import { CustomTimeRangeTypes, TimeRanges } from '@colven/common-domain-lib/lib';
import stateService from '@/business/state.service';
import harvestFrontCurrentStateApi from '@/api/harvestFrontCurrentState.api'
import driveUnitApi from '@/api/drive-unit.api'

export default {
  name: 'HistoryTruckDispatcherComponent',
  components: {
    AutocompleteComponent,
    FrontSelectorDialogComponent,
    WaitEmptyTrucksComponent,
    TruckDialogComponent,
    HarvesterDialogComponent,
    FrontDialogComponent,
    TruckDispatchComponent,
    SelectorComponent
  },
  data: () => ({
    orderConfigurationsId: ConfigurationsConstants.ConfigurationKeys.FRONTS_ORDER_KEY,
    selectedFrontconfigurationsId: ConfigurationsConstants.ConfigurationKeys.SELECTED_FRONTS_KEY,
    deviationTimesKey: ConfigurationsConstants.HARVEST_FRONT_DEVIATION_TIMES_KEY,

    showTruckDialog: false,
    infoTruckModal: null,
    currentFront: null,
    showFrontDialog:null,
    showHarvesterDialog: false,
    infoHarvestModal: null,
    loadingTable: false,
    harvestFrontsFilter: {
      id: '_id',
      name: i18n.t('smartTruckDispatcher.harvestFrontsSelector'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    showFrontsSelectorDialog: false,
    selectedTab: 'tab-summary',
    filterSwitch: true,
    thingsMap: new Map,
    availableTrucks: [],
    waitingEmptyTrucks: [],
    harvestFronts: [],
    harvestFrontsTracks: [],
    harvestFrontsTracksMap: new Map(),
    configurations: null,
    orderConfigurations: null,
    selectedTruck: null,
    harvestFrontMap: new Map(),
    categoryMap: new Map(),
    showTruckDispatchDialog: false,
    selectedFronts: [],
    refreshTrucksSelector:0,
    categories:[],
    //slider - timer
    slider: {
      min: 0,
      max: 10
    },            
    timestampValue: 0,
    hourSelected: '00',
    hourSelector: HOUR_SELECTOR,
    playInterval: null,
    drawer: true,
    filters: null,
    selectorModel: selectorService.getSelectorModel(false),
    statesTypesMap: new Map(),
    deviationTime:null,
    loadingSpinner:false,
    randomkeyNewFront:0,
    driveUnits:[],

  }),
  computed: {
    currentTime() {
        return (this.timestampValue ? moment(this.timestampValue * 1000).format("DD/MM/YYYY HH:mm:ss") : "");
    }
},
  async created() {
    const selectedEnterprise = JSON.parse(localStorage.getItem('enterpriseId'))
    const categories = await enterpriseApi.getCategoriesPaginated(null, null, selectedEnterprise);
    const things = await thingApi.getThingByTypeKey(TYPE_KEY.TRUCK);
    this.driveUnits = await driveUnitApi.getByEnterprise();
    this.updateCategoryMap(categories.data);
    this.updateThingsMap(things);
  },
  beforeDestroy() {
    clearInterval(this.playInterval)
  },
  async mounted() {
    await this.getStateTypes();
    await this.initSelector();
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    await this.getConfiguration();
    await this.getData();
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    /* timer */
    async initSelector() {
      this.selectorModel.hideAutoReport = true;
      this.selectorModel.showTimeFormatSelector = false;
      this.selectorModel.filterSwitch.value = true;
      this.selectorModel.hideEquipmentConfigSwitch = true;
      this.selectorModel.hideEquipmentConfigSwitch = true;
      this.selectorModel.disableGetReportButton = false;
      this.selectorModel.selectedCount = 0;
      this.selectorModel.selectionLimit = 60;
      this.selectorModel.selectedDateAndTimeRange = TimeRanges.CUSTOM;
      this.selectorModel.singleDaySelect = true;
      this.selectorModel.selectedDateAndTimeRangeCustomType = CustomTimeRangeTypes.DATE;

      this.enterprise = JSON.parse(localStorage.getItem('enterpriseId'))
      const harvestFronts = (await thingApi.getHarvestFronts()).data.filter(t => !t.deleted && t.enterpriseId == this.enterprise).sort((a, b) => a.name.localeCompare(b.name))
      const harvestFrontsMapped = harvestFronts.map(front=> { return {id: front._id, name: front.name}})
      this.harvestFrontsFilter.data.cleanAndUpdate(harvestFrontsMapped)
      this.selectorModel.filters.push(this.harvestFrontsFilter)
      this.selectorModel.sinceDate =  moment(new Date().getTime()).format('YYYY-MM-DD');
  },
  async getStateTypes() {
    const statesTypesResponse = await stateService.getStatesTypesByEnterpriseId();
    let lang = localStorage.getItem('locale') || i18n.locale.split('-')[0]
    for (const statesTypes of statesTypesResponse) {
      this.statesTypesMap.set(statesTypes.key, statesTypes.name[lang]);
    }
  },
  async changeLinetime(value) {
    //busco el valor anterior multiplo de 5, para que el timer avance cada 5 segundos
    const time = Math.floor(value / 5) * 5;
    this.harvestFronts = [];
    let frontsTracksMap = this.harvestFrontsTracksMap.get(time);
    if(!frontsTracksMap.length){//para los primeros segundos 
      frontsTracksMap = this.harvestFrontsTracksMap.get(value);
    }
    frontsTracksMap.forEach(front => {
      front.serverTimestamp = time
    });
    this.harvestFronts = smartTruckDispatcherService.parseHarvestFrontsStatus(frontsTracksMap, this.harvestFrontMap, this.availableTrucks,this.categories,this.statesTypesMap, this.deviationTime,this.driveUnits);
  },
  async playHistory() {
      if (!this.playInterval) {
          this.playInterval = setInterval(() => {
              this.changeLinetime(++this.timestampValue);
              if (this.slider.max === this.timestampValue) {
                  this.pauseHistory();
              }
          }, 500);
      }
  },
  async pauseHistory() {
      clearInterval(this.playInterval);
      this.playInterval = null;
  },
  async hourChanged(hour) {
    this.pauseHistory();
    const daySelected = moment(this.filters.sinceDate, 'YYYY-MM-DD').unix();
    this.slider.min = daySelected + (parseInt(hour) * 3600);
    this.slider.max = this.slider.min + 3599;
    this.timestampValue = this.slider.min;
    await this.changeLinetime(this.timestampValue);
},
  /* timer */
    updateCategoryMap({ data: categories }) {
      if (categories && categories.length > 0) {
        for (const category of categories) {
          this.categoryMap.set(category._id, category.name);
        }
      }
    },
    updateThingsMap({ data: things }) {
      if (things && things.length > 0) {
        for (const thing of things) {
          this.thingsMap.set(thing._id, thing.name);
        }
      }
    },

    showTruckDialogfunction(infoTruckModal, currentFront) {
      this.infoTruckModal = infoTruckModal;
      this.currentFront = currentFront;
      this.categoriesFronts = this.harvestFronts.filter(front=> front.category._id === infoTruckModal.category._id)
      this.showTruckDialog = true
    },
    closeTruckDialog() {
      this.showTruckDialog = false
    },

    showFrontDialogfunction(currentFront) {
      this.currentFront = currentFront;
      this.showFrontDialog = true
    },
    closeFrontDialog() {
      this.showFrontDialog = false
    },

    showHarvestDialogFunction(infoHarvestModal, currentFront) {
      this.infoHarvestModal = infoHarvestModal;
      this.currentFront = currentFront;
      this.showHarvesterDialog = true
    },
    closeHarvesterDialog() {
      this.showHarvesterDialog = false
    },

    async getData() {
      this.loadingTable = true;
      try {
        //traigo los camiones asignados a canchon con categoria y status
        this.availableTrucks = await smartTruckDispatcherService.getThingsData();
        this.waitingEmptyTrucks = this.getWaitingEmptyTrucks(this.availableTrucks);
        const selectedEnterprise =JSON.parse(localStorage.getItem('enterpriseId'))
        this.categories = (await enterpriseApi.getCategoriesPaginated(0, 0, selectedEnterprise)).data.data;
      } finally {
        this.loadingTable = false;
      }
    },
    getWaitingEmptyTrucks(trucks) {
      return trucks.filter(truck => truck.state && truck.state.type && truck.state.type.key === ConfigurationsConstants.STATUS_ESPERA_VACIO && (!truck.trackData || !truck.trackData.coupledThings));
    },
    sortData() {
      this.harvestFronts.sort((a, b) => this.compareFn(a, b))
    },
    compareFn(a, b) {//funcion que compara 2 frentes para ordenarlos
      //primero compara por el campo pinned, para que los frentes fijados vayan al pie de la lista
      if (a.pinned > b.pinned) return 1
      if (a.pinned < b.pinned) return -1

      else { //despues compara por los campos nombre y categoría
        //me fijo si  el usuario tiene configurado filtros
        if (this.orderConfigurations) {
          if (this.orderConfigurations.orderBy === ConfigurationsConstants.Orders.NAME_ASCENDING) {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
          }
          else if (this.orderConfigurations.orderBy === ConfigurationsConstants.Orders.NAME_DESCENDING) {
            if (a.name < b.name) return 1
            if (a.name > b.name) return -1
          }
          else if (this.orderConfigurations.orderBy === ConfigurationsConstants.Orders.CATEGORY_ASCENDING) {
            if (a.category.name > b.category.name) return 1
            if (a.category.name < b.category.name) return -1
          }
          else {
            if (a.category.name < b.category.name) return 1
            if (a.category.name > b.category.name) return -1
          }
          return 0;
        }
        else {
          if (a.name > b.name) return 1
          if (a.name < b.name) return -1
          return 0;
        }
      }
    },

    async getConfiguration() {
      const orderConfig = await configurationService.get(this.orderConfigurationsId)
      if (orderConfig) {
        this.orderConfigurations = orderConfig.data;
      }
      const selectedFrontConfig = await configurationService.get(this.selectedFrontconfigurationsId)
      if (selectedFrontConfig) {
        this.configurations = selectedFrontConfig;
        if (selectedFrontConfig.data.selectedFronts) {
          this.selectedFronts = selectedFrontConfig.data.selectedFronts
          //asigno por defecto los camiones que tiene el usuario configurados
          this.selectorModel.filters[0].selectedData = this.selectorModel.filters[0].data.filter(front => this.selectedFronts.includes(front.id))
          this.selectorModel.filters[0].selectorDialogItems = this.selectedFronts;
        }
      }
      if(!this.deviationTime){
        this.deviationTime = await harvestFrontCurrentStateApi.getConfiguration(this.deviationTimesKey)
      }
    },
    applyConfigurations() {
      this.sortData();
    },
    async getReport(filters) {
      this.loadingSpinner = true;
      try {
          this.drawer = false;
          this.filters = filters;
          const harvestFrontsSelected = filters.filters[0].selectorDialogItems;
          const daySelected = moment(filters.sinceDate, 'YYYY-MM-DD').unix();
           this.slider.min = daySelected;
           this.slider.max = daySelected + 86399;//23:59:59

           this.harvestFrontMap = new Map();
           const harvestFronts = await filterService.getAllHarvestFront();
           if (harvestFronts && harvestFronts.length > 0) {
             for (const harvestFront of harvestFronts) {
               if (harvestFrontsSelected.includes(harvestFront.id)) {
                 this.harvestFrontMap.set(harvestFront.id, harvestFront.name);
               }
             }
           }
           const harvestFrontStatus = await smartTruckDispatcherService.getHarvestFrontStatusHistory(Array.from(harvestFrontsSelected),this.slider.min,this.slider.max);
           this.harvestFrontsTracksMap = this.completeTracks(this.slider.min,this.slider.max,harvestFrontsSelected,harvestFrontStatus)
           this.changeLinetime(this.slider.min);
           this.randomkeyNewFront = Math.ceil(Math.random() * 1000000);
      } finally {
          this.loadingSpinner = false;
      }
    },
    
    completeTracks(minTimestamp, maxTimestamp, harvestFrontsSelected, tracks){
      //reviso, del array de "harvestFrontsSelected", sacar los que no tienen tramas
      const harvestFrontsSelectedWithTracks = [];
      harvestFrontsSelected.forEach(harvestFront => {
        const FrontTracks = tracks.filter(front => front.harvestFrontId === harvestFront)
        if(FrontTracks.length) harvestFrontsSelectedWithTracks.push(harvestFront)
      });
      
      //completo el map 
      const harvestFrontsTracks = new Map();
      for (let index = minTimestamp; index < maxTimestamp; index++) { 
        const currentTracks = [];
        harvestFrontsSelectedWithTracks.forEach(harvestFront => {
          //busco las tramas del frente
          const frontTracks = tracks.filter(front => front.harvestFrontId === harvestFront)
          //busco las tramas del frente anteriores a la hora actual ( para buscar luego la maxima)
          const prevfrontTracks = frontTracks.filter(front => front.serverTimestamp <= index )
          //traigo la ultima trama previa a la hora actual (si no hay ninguna, le paso la primer trama que tengo de ese frente)
          const currentTrack = prevfrontTracks.reduce((prev, current) => 
            current.serverTimestamp > prev.serverTimestamp ? current : prev, frontTracks[0])
          currentTracks.push(currentTrack);
        });
        harvestFrontsTracks.set(index,currentTracks)
      }
      return harvestFrontsTracks;
    },

    findClosestValue(arr, target) {
      if(arr.length){
        let values = arr.filter(track => track.serverTimestamp <= target )
        if(arr.length && !values.length){
          values.push(arr[0]);
        }
        return values.reduce((nearly, curr) => 
          curr.serverTimestamp > nearly.serverTimestamp  ? curr : nearly
         );
      }
    },
  },
  watch: {

  }
}
