import { render, staticRenderFns } from "./HistoricTruckDispatcherComponent.vue?vue&type=template&id=647e7758&scoped=true"
import script from "./HistoricTruckDispatcherComponent.js?vue&type=script&lang=js&external"
export * from "./HistoricTruckDispatcherComponent.js?vue&type=script&lang=js&external"
import style0 from "./HistoricTruckDispatcherComponent.vue?vue&type=style&index=0&id=647e7758&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647e7758",
  null
  
)

export default component.exports